import React from "react";
import docImg from "../../assets/images/homeSection4.svg";
import nha from "../../assets/images/nha.svg";
import aamca from "../../assets/images/amca.svg";
import msa from "../../assets/images/msa.svg";
import ptc from "../../assets/images/ptc.svg";
import { useNavigate } from "react-router-dom";

function Section4() {
  const navigation = useNavigate();

  return (
    <div className="w-full font-poppins flex items-center flex-col justify-center">
      <p className="font-[600] text-[24px] md:text-[35px] xl:text-[48px] text-primary">
        Accreditation
      </p>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[92%] flex mt-10  flex-col xl:flex-row gap-10">
        <div className="flex gap-8 md:gap-10  991:gap-20 flex-col md:flex-row">
          <div className="w-full md:w-[50%] rounded-[15px]">
            <img src={docImg} className="h-full object-cover rounded-[15px]" />
          </div>
          <div className="w-full md:w-[50%] flex font-[400] text-[14px] md:text-[16px] text-[#5C616E] flex-col gap-8">
            <div className="w-[100%]  flex flex-col gap-2">
              <p>
                The Param Institute of Education Career and Technical Institute is a Candidate for Accreditation by the Commission on Secondary Schools of the Middle States Association of Colleges and Schools, 3819-33 Chestnut Street, Suite 310, Philadelphia, PA 19104-3171 (267-284-5000) effective Date May 12 2023
              </p>
              <p>
                PTCB-Recognized Education/Training Program for Certified
                Pharmacy Technician (CPhT) PTCB-Recognized Education/Training
                Program for Certified Compounded Sterile Preparation Technician
                (CSPT)
              </p>
            </div>
            <ul className="list-disc">
              <div className="flex flex-col pl-5 gap-4 md:gap-5">
                <li>National Health career Association (NHA)</li>
                <li>American Medical Certification Associations (AMCA)</li>
                <li>
                  Accepted Candidacy of Middle States Association Commissions on
                  Elementary and Secondary Schools (MSA-CESS)
                </li>
                <li>Pharmacy Technician Certification Board (PTCB)</li>
              </div>
            </ul>
            <div className="hidden xl:flex md:grid-cols-4 w-[92%] justify-between py-2 items-center">
              <a href="https://www.nhanow.com/" target="_blank">
                {" "}
                <img src={nha} />
              </a>
              <a href="https://www.amcaexams.com/" target="_blank">
                {" "}
                <img src={aamca} />
              </a>
              <a href="https://www.ptcb.org/" target="_blank">
                <img src={ptc} />
              </a>
            </div>
            <div className="hidden xl:block">
              <button
                className="orangeHoverButton"
                onClick={() => {
                  navigation("accreditation");
                }}
              >
                Learn More
              </button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 991:grid-cols-4 gap-10 991:gap-0 991:grid-flow-col xl:hidden  py-2 items-center">
          <a
            href="https://www.nhanow.com/"
            target="_blank"
            className="flex justify-center"
          >
            {" "}
            <img src={nha} />
          </a>
          <a
            href="https://www.amcaexams.com/"
            target="_blank"
            className="flex justify-center"
          >
            {" "}
            <img src={aamca} />
          </a>
          <a href="https://www.ptcb.org/" target="_blank" className="flex justify-center">
                <img src={ptc} />
          </a>

          {/* <div className='flex'>
                        <a href='https://www.nhanow.com/' target='_blank'>  <img src={nha} /></a>
                        <a href='https://www.amcaexams.com/' target='_blank'> <img src={aamca} /></a>
                    </div>
                    <div className='flex'>
                       
                    </div> */}
        </div>
        <div className="flex xl:hidden justify-center">
          <button
            className="orangeHoverButton"
            onClick={() => {
              navigation("accreditation");
            }}
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
}

export default Section4;
