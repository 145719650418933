import React from "react";
import nha from "../../assets/images/aboutusNHA.svg";
import aamca from "../../assets/images/aboutusAMCA.svg";
import msa from "../../assets/images/aboutusMSA.svg";
import ptc from "../../assets/images/aboutusPTBC.svg";
import NJBON from "../../assets/images/NJBON.svg";
import NJDOL from "../../assets/images/NJDOL.svg";
import NJDOE from "../../assets/images/NJDOE.svg";
import { useNavigate } from "react-router-dom";

function Accreditation() {
  const navigation = useNavigate();
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28">
      <div className="bg-accreditation-bg bg-[60%] md:bg-[center] w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] 2xl:text-[60px] text-white w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-center">
          Approval & Accreditation
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-12 md:mt-24 flex flex-col gap-10 md:gap-20">
        <p className="font-[500] text-[24px] md:text-[32px] text-[#003C9D]">
          Affiliations
        </p>
        <div className="grid grid-cols-2 md:grid-cols-4 w-[95%] justify-between py-2 items-center gap-10 self-center">
          <a
            href="https://www.nhanow.com/"
            target="_blank"
            className="flex items-center md:justify-center"
          >
            {" "}
            <img className="h-[70px] md:h-[96px] xl:h-full" src={nha} />
          </a>
          <a
            href="https://www.amcaexams.com/"
            target="_blank"
            className="flex items-center md:justify-center"
          >
            {" "}
            <img className="h-[70px] md:h-[96px] xl:h-full" src={aamca} />
          </a>
          <a
            href="https://www.ptcb.org/"
            target="_blank"
            className="flex items-center justify-center md:justify-center"
          >
            <img className="h-[70px] md:h-[96px] xl:h-full" src={ptc} />
          </a>
        </div>
        <div>
          <ul className="list-disc font-[500] text-[20px] text-[#F37021]  pl-10 flex flex-col gap-10">
            <div>
              <a href="https://www.nhanow.com/" target="_blank">
                {" "}
                <li className="underline">
                  National Healthcareer Association (NHA)
                </li>
              </a>
              <p className="text-[#5C616E] font-[400]  mt-2 leading-[40px]">
                Contact: (800) 499-9092
              </p>
	     <p>The Param Institute of Education Career and Technical Institute is a Candidate for Accreditation by the Commission on Secondary Schools of the Middle States Association of Colleges and Schools, 3819-33 Chestnut Street, Suite 310, Philadelphia, PA 19104-3171 (267-284-5000) effective Date May 12 2023</p>
            </div>
		
            <div>
              <a href="https://www.amcaexams.com/" target="_blank">
                {" "}
                <li className="underline">
                  American Medical Certification Associations (AMCA)
                </li>
              </a>
              <p className="text-[#5C616E] font-[400]  mt-2 leading-[40px]">
                Contact: (973) 582-1800
              </p>
            </div>
            <div>
              <a href="https://www.ptcb.org/" target="_blank">
                {" "}
                <li className="underline">
                  Pharmacy Technician Certification Board (PTCB)
                </li>
              </a>
              <p className="text-[#5C616E] font-[400]  mt-2 leading-[40px]">
                PTCB-Recognized Education/Training Program for Certified
                Pharmacy Technician (CPhT)
                <br />
                PTCB-Recognized Education/Training Program for Certified
                Compounded Sterile Preparation Technician (CSPT)
                <br />
              </p>
              <p className="mt-3 text-[#5C616E] font-[400]">
                Contact: (800) 363-8012
              </p>
            </div>
          </ul>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-20 flex flex-col gap-[60px] md:gap-20 items-center">
        <p className="font-[500] text-[24px] md:text-[32px] text-[#003C9D] w-full">
          State Licensure
        </p>
        <div className="flex grid-cols-3 w-full md:w-[65%] justify-between py-2 items-center">
          <a
            href="https://www.nj.gov/labor/labormarketinformation/contact-us/COEI.shtml"
            target="_blank"
          >
            {" "}
            <img src={NJDOL} className="h-[80px] md:h-auto" />
          </a>
          <a
            href="https://www.nj.gov/education/cte/postsec/ppcs/"
            target="_blank"
          >
            {" "}
            <img src={NJDOE} className="h-[80px] md:h-auto" />
          </a>
          <a href="https://www.njconsumeraffairs.gov/nur" target="_blank">
            {" "}
            <img src={NJBON} className="h-[80px] md:h-auto" />
          </a>
        </div>
        <div className="w-[100%]">
          <ul className="list-disc font-[500] text-[20px] text-[#F37021]  pl-10 flex flex-col gap-10 break-words">
            <div>
              <a
                href="https://www.nj.gov/labor/labormarketinformation/contact-us/COEI.shtml"
                target="_blank"
              >
                <li className="underline">
                  {" "}
                  New Jersey Department of Labor and Workforce Development
                  (NJDOL)
                </li>
              </a>
              <p className="text-[#5C616E] font-[400]  mt-2 leading-[27px] md:leading-[40px]">
                Contact: trainingevaluationunit@dol.nj.gov, Training Evaluation
                Unit
              </p>
            </div>
            <div>
              <a
                href="https://www.nj.gov/education/cte/postsec/ppcs/"
                target="_blank"
              >
                <li className="underline">
                  New Jersey Department of Education (NJDOE)
                </li>
              </a>
              <p className="text-[#5C616E] font-[400]  mt-2 leading-[27px] md:leading-[40px]">
                Contact: (609)376-3500, Postsecondary Private Career School Unit
              </p>
            </div>
            <div>
              <a href="https://www.njconsumeraffairs.gov/nur" target="_blank">
                <li className="underline">
                  Division of Consumer Affairs New Jersey Board of Nursing
                  (NJBON)
                </li>
              </a>
              <p className="text-[#5C616E] font-[400]  mt-2 leading-[27px] md:leading-[40px]">
                Contact (973) 504-6430
              </p>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Accreditation;
