// import React, { useEffect, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getCourses,
//   addReuestInfo,
//   addReuestInfoSuccess,
// } from "../redux/actions/common.action";
// import * as Yup from "yup";
// import { Formik, Field, Form, ErrorMessage } from "formik";
// import { addRequestInfoAPI } from "../redux/Api";
// import { useNavigate } from "react-router-dom";
// import { getEducationLevel } from "../redux/actions/educationLevel.action";
// import { useLocation } from "react-router-dom";
// import { imageGenerator } from "../utils/imageGenerator";
// import useLoader from "../hooks/useLoader";

// function RequestInfo() {
//   const dispatch = useDispatch();
//   const { isLoading, setLoader, Loader } = useLoader();
//   const { getcourses_data } = useSelector((state) => state.CommonReducer);
//   const { addrequestinfo_data } = useSelector((state) => state.CommonReducer);
//   const { educationLevelData } = useSelector(
//     (state) => state.educationLevelReducer
//   );
//   const navigate = useNavigate();
//   const location = useLocation();
//   const title = location.state?.title;
//   const formRef = useRef(null);

//   //----------------------------------------------------------USE EFFECT FOR GET COURSES-----------------------------------------------------------------//
//   useEffect(() => {
//     dispatch(getCourses());
//     dispatch(getEducationLevel());
//   }, [dispatch]);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [location]);

//   useEffect(() => {
//     if (addrequestinfo_data && addrequestinfo_data.status == 200) {
//       setLoader(false);
//       const message = addrequestinfo_data.data.message;
//       dispatch(addReuestInfoSuccess(null));
//       navigate("../thankYou", {
//         state: { message: message },
//       });
//     }
//   }, [addrequestinfo_data]);
//   //----------------------------------------------------------USE EFFECT FOR GET COURSES-----------------------------------------------------------------//
//   const validationSchema = Yup.object().shape({
//     firstName: Yup.string().required("First Name is required"),
//     lastName: Yup.string().required("Last Name is required"),
//     email: Yup.string()
//       .email("Please enter valid email")
//       .required("Email is required"),
//     phoneNumber: Yup.string().required("Phone Number is required"),
//     programOfIntrest: Yup.string().required("Please select an option"),
//     educationLevel: Yup.string().required("Please select an option"),
//     permission: Yup.boolean().required(
//       "Please select checkbox of terms & conditions."
//     ),
//     message: Yup.string().when("_", {
//       is: () => !title,
//       then: (schema) => schema.required("Message is required."),
//       otherwise: (schema) => schema.optional(),
//     }),
//   });

//   const handleSubmit = async (values, { resetForm }) => {
//     const pdf = await imageGenerator(formRef.current);

//     const payload = {
//       firstname: values.firstName,
//       lastname: values.lastName,
//       email: values.email,
//       phonenumber: values.phoneNumber,
//       education_level_id: values.educationLevel,
//       course_id: values.programOfIntrest,
//       message: values.message ? values.message : "",
//       pdfFile: pdf,
//     };

//     if (title) {
//       if (values.programOfIntrest == 25) {
//         window.open(
//           "https://drive.google.com/file/d/17Ld7eCFKvhcuK74O0naVH6H4YOWwRGm1/view",
//           "_blank",
//           "noopener,noreferrer"
//         );
//       } else {
//         window.open(
//           "https://drive.google.com/file/d/1DvT2elOtTCn4m2Gbevn5u3lxiD66ZHTr/view",
//           "_blank",
//           "noopener,noreferrer"
//         );
//       }
//     }

//     dispatch(addReuestInfo(payload));
//     setLoader(true);
//     resetForm();
//   };

//   return (
//     <div className="mb-[-40px] flex justify-center">
//       <iframe
//         origin
//         id="JotFormIFrame-231016193942048"
//         title="Enrollment"
//         onload="window.parent.scrollTo(0,0)"
//         allowtransparency="true"
//         allowfullscreen="true"
//         allow="geolocation; microphone; camera"
//         src="https://form.jotform.com/231016193942048?isIframeEmbed=1"
//         frameborder="0"
//         scrolling="no"
//         className="min-w-full h-[1280px] 576:h-[1120px] "
//       ></iframe>
//     </div>
//     // <div>
//     //   <div
//     //     className="bg-[#F5F5F5] font-poppins px-4 md:px-8  xl:px-10 py-12 flex flex-col items-center gap-12 -mb-10"
//     //     ref={formRef}
//     //   >
//     //     {isLoading ? (
//     //       <Loader type={"component"} />
//     //     ) : (
//     //       <>
//     //         <p className="font-[600] text-[32px] md:text-[40px] xl:text-[42px] text-[#F37021]">
//     //           {title ? title : "Inquiry Form"}
//     //         </p>
//     //         <Formik
//     //           initialValues={{
//     //             firstName: "",
//     //             lastName: "",
//     //             email: "",
//     //             phoneNumber: "",
//     //             message: "",
//     //             programOfIntrest: "",
//     //             educationLevel: "",
//     //             permission: "",
//     //           }}
//     //           validationSchema={validationSchema}
//     //           onSubmit={handleSubmit}
//     //         >
//     //           {({ errors, touched }) => (
//     //             <Form className="w-full flex flex-col items-center gap-8">
//     //               <div className="w-full xl:w-[90%] flex gap-8 md:gap-4 xl:gap-5 flex-col md:flex-row">
//     //                 <div className=" w-full flex flex-col gap-2">
//     //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
//     //                     First Name <span className="text-[#BB2727]">*</span>
//     //                   </label>
//     //                   <Field
//     //                     type="text"
//     //                     name="firstName"
//     //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
//     //                       errors.firstName && touched.firstName
//     //                         ? "border-red-500"
//     //                         : ""
//     //                     }`}
//     //                   />
//     //                   <ErrorMessage
//     //                     name="firstName"
//     //                     component="div"
//     //                     className="text-red-500"
//     //                   />
//     //                 </div>
//     //                 <div className="w-full flex flex-col gap-2">
//     //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
//     //                     Last Name <span className="text-[#BB2727]">*</span>
//     //                   </label>
//     //                   <Field
//     //                     type="text"
//     //                     name="lastName"
//     //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
//     //                       errors.lastName && touched.lastName
//     //                         ? "border-red-500"
//     //                         : ""
//     //                     }`}
//     //                   />
//     //                   <ErrorMessage
//     //                     name="lastName"
//     //                     component="div"
//     //                     className="text-red-500"
//     //                   />
//     //                 </div>
//     //               </div>
//     //               <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
//     //                 <div className=" w-full flex flex-col gap-2">
//     //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
//     //                     Email Address <span className="text-[#BB2727]">*</span>
//     //                   </label>
//     //                   <Field
//     //                     type="text"
//     //                     name="email"
//     //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
//     //                       errors.email && touched.email ? "border-red-500" : ""
//     //                     }`}
//     //                   />
//     //                   <ErrorMessage
//     //                     name="email"
//     //                     component="div"
//     //                     className="text-red-500"
//     //                   />
//     //                 </div>
//     //                 <div className="w-full flex flex-col gap-2">
//     //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
//     //                     Phone <span className="text-[#BB2727]">*</span>
//     //                   </label>
//     //                   <Field
//     //                     type="text"
//     //                     name="phoneNumber"
//     //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
//     //                       errors.phoneNumber && touched.phoneNumber
//     //                         ? "border-red-500"
//     //                         : ""
//     //                     }`}
//     //                   />
//     //                   <ErrorMessage
//     //                     name="phoneNumber"
//     //                     component="div"
//     //                     className="text-red-500"
//     //                   />
//     //                 </div>
//     //               </div>
//     //               <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
//     //                 <div className="w-full md:w-[calc(50%_-_10px)] flex flex-col gap-2">
//     //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
//     //                     Education Level{" "}
//     //                     <span className="text-[#BB2727]">*</span>
//     //                   </label>
//     //                   <Field
//     //                     name="educationLevel"
//     //                     as="select"
//     //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
//     //                       errors.educationLevel && touched.educationLevel
//     //                         ? "border-red-500"
//     //                         : ""
//     //                     }`}
//     //                   >
//     //                     <option value="" disabled hidden>
//     //                       Select Education
//     //                     </option>
//     //                     {educationLevelData?.data?.map((item) => (
//     //                       <option value={item.id}>
//     //                         {item.educationName || item.educationname}
//     //                       </option>
//     //                     ))}
//     //                   </Field>
//     //                   <ErrorMessage
//     //                     name="educationLevel"
//     //                     component="div"
//     //                     className="text-red-500"
//     //                   />
//     //                 </div>
//     //                 <div className="w-full md:w-[calc(50%_-_10px)] flex flex-col gap-2">
//     //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
//     //                     Program Of Interest{" "}
//     //                     <span className="text-[#BB2727]">*</span>
//     //                   </label>
//     //                   <Field
//     //                     as="select"
//     //                     name="programOfIntrest"
//     //                     type="text"
//     //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
//     //                       errors.programOfIntrest && touched.programOfIntrest
//     //                         ? "border-red-500"
//     //                         : ""
//     //                     }`}
//     //                   >
//     //                     <option value="" disabled hidden>
//     //                       Select Program
//     //                     </option>
//     //                     {getcourses_data?.data?.map((course) => (
//     //                       <option value={course.id}>{course.coursename}</option>
//     //                     ))}
//     //                   </Field>
//     //                   <ErrorMessage
//     //                     name="programOfIntrest"
//     //                     component="div"
//     //                     className="text-red-500"
//     //                   />
//     //                 </div>
//     //               </div>
//     //               {!title && (
//     //                 <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
//     //                   <div className=" w-full flex flex-col gap-2">
//     //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
//     //                       Message <span className="text-[#BB2727]">*</span>
//     //                     </label>
//     //                     <Field
//     //                       as="textarea"
//     //                       name="message"
//     //                       rows="5"
//     //                       className={` border border-[#989898] rounded-[5px] p-3 ${
//     //                         errors.message && touched.message
//     //                           ? "border-red-500"
//     //                           : ""
//     //                       }`}
//     //                     />
//     //                     <ErrorMessage
//     //                       name="message"
//     //                       component="div"
//     //                       className="text-red-500"
//     //                     />
//     //                   </div>
//     //                 </div>
//     //               )}

//     //               <div className="xl:w-[90%]">
//     //                 <div className="flex gap-3 w-full">
//     //                   <Field
//     //                     id="permission"
//     //                     name="permission"
//     //                     type="checkbox"
//     //                     className="h-[21px] w-[21px]"
//     //                   />
//     //                   <label
//     //                     htmlFor="permission"
//     //                     className="font-[400] text-[14px] md:text-[16px] text-primary"
//     //                   >
//     //                     By clicking the submit button, I give PIE Education
//     //                     permission to call, text, and/or email me information
//     //                     about its educational programs at the phone number and
//     //                     email address provided.
//     //                   </label>
//     //                 </div>

//     //                 <ErrorMessage
//     //                   name="permission"
//     //                   component="div"
//     //                   className="text-red-500 mt-2"
//     //                 />
//     //               </div>
//     //               <div className="flex gap-3 w-full xl:w-[90%] justify-center mt-3">
//     //                 <button type="submit" className="orangeHoverButton">
//     //                   Submit
//     //                 </button>
//     //                 <button
//     //                   type="button"
//     //                   className="orangeOutlineButton"
//     //                   onClick={() => navigate(-1)}
//     //                 >
//     //                   Cancel
//     //                 </button>
//     //               </div>
//     //             </Form>
//     //           )}
//     //         </Formik>
//     //       </>
//     //     )}
//     //   </div>
//     // </div>
//   );
// }

// export default RequestInfo;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCourses,
  addReuestInfo,
  addReuestInfoSuccess,
} from "../redux/actions/common.action";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { addRequestInfoAPI } from "../redux/Api";
import { useNavigate } from "react-router-dom";
import { getEducationLevel } from "../redux/actions/educationLevel.action";
import { useLocation } from "react-router-dom";
import { imageGenerator } from "../utils/imageGenerator";
import useLoader from "../hooks/useLoader";

function RequestInfo() {
  const dispatch = useDispatch();
  const { isLoading, setLoader, Loader } = useLoader();
  const { getcourses_data } = useSelector((state) => state.CommonReducer);
  const { addrequestinfo_data } = useSelector((state) => state.CommonReducer);
  const { educationLevelData } = useSelector(
    (state) => state.educationLevelReducer
  );
  const navigate = useNavigate();
  const location = useLocation();
  const title = location.state?.title;
  const guideline = location.state?.guideline;
  const course = location.state?.course;
  const formRef = useRef(null);

  //----------------------------------------------------------USE EFFECT FOR GET COURSES-----------------------------------------------------------------//
  useEffect(() => {
    dispatch(getCourses());
    dispatch(getEducationLevel());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (addrequestinfo_data && addrequestinfo_data.status == 200) {
      setLoader(false);
      const message = addrequestinfo_data.data.message;
      dispatch(addReuestInfoSuccess(null));
      navigate("../thankYou", {
        state: { message: message },
      });
    }
  }, [addrequestinfo_data]);
  //----------------------------------------------------------USE EFFECT FOR GET COURSES-----------------------------------------------------------------//
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    programOfIntrest: Yup.string().required("Please select an option"),
    educationLevel: Yup.string().required("Please select an option"),
    permission: Yup.boolean().required(
      "Please select checkbox of terms & conditions."
    ),
    message: Yup.string().when("_", {
      is: () => !title,
      then: (schema) => schema.required("Message is required."),
      otherwise: (schema) => schema.optional(),
    }),
  });



  const handleSubmit = async (values, { resetForm }) => {
    const pdf = await imageGenerator(formRef.current);

    const payload = {
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      phonenumber: values.phoneNumber,
      education_level_id: values.educationLevel,
      course_id: values.programOfIntrest,
      message: values.message ? values.message : "",
      pdfFile: pdf,
    };

    if (title) {
      if (values.programOfIntrest == 25) {
        window.open(
          "https://drive.google.com/file/d/17Ld7eCFKvhcuK74O0naVH6H4YOWwRGm1/view",
          "_blank",
          "noopener,noreferrer"
        );
      } else {
        window.open(
          "https://drive.google.com/file/d/1DvT2elOtTCn4m2Gbevn5u3lxiD66ZHTr/view",
          "_blank",
          "noopener,noreferrer"
        );
      }
    }

    dispatch(addReuestInfo(payload));
    setLoader(true);
    resetForm();
  };
  // useEffect(() => {
  //   setTimeout(() => {
  //     window.open(
  //       "https://drive.google.com/file/d/1DvT2elOtTCn4m2Gbevn5u3lxiD66ZHTr/view",
  //       "_blank",
  //       "noopener,noreferrer"
  //     );
  //   }, 60000);
  // }, []);
  const iframeRef = useRef(null);
  const [count, setCount] = useState(0);
  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && title) {
      iframe.onload = () => {
        if (count < 1) {
          setCount((n) => n + 1);
        } else if (count < 3 && count > 0) {
          if (course == "all") {
            window.open(
              // "https://drive.google.com/file/d/1DvT2elOtTCn4m2Gbevn5u3lxiD66ZHTr/view",
              "https://drive.google.com/file/d/1YQMT2nHc7OiV12w15HjPvIkZEJc6qh1L/view",
              "_blank",
              "noopener,noreferrer"
            );
          } else if (course == "lpn") {
            window.open(
              "https://drive.google.com/file/d/17Ld7eCFKvhcuK74O0naVH6H4YOWwRGm1/view",
              "_blank",
              "noopener,noreferrer"
            );
          }

          setCount(100);
        }
      };
    }
  }, [count]);

  return (
    <div className="mb-[-40px] flex justify-center flex-col">
      {title&&<p className="text-center text-red-500 font-[500] text-[24px] mt-8 font-poppins">{title}</p>}
      <iframe
        ref={iframeRef}
        origin
        id="JotFormIFrame-231016193942048"
        title="Enrollment"
        onload="window.parent.scrollTo(0,0)"
        allowtransparency="true"
        allowfullscreen="true"
        allow="geolocation; microphone; camera"
        src="https://form.jotform.com/231016193942048?isIframeEmbed=1"
        frameborder="0"
        scrolling="no"
        className="min-w-full h-[1280px] 576:h-[1120px] "
      ></iframe>
    </div>
    // <div>
    //   <div
    //     className="bg-[#F5F5F5] font-poppins px-4 md:px-8  xl:px-10 py-12 flex flex-col items-center gap-12 -mb-10"
    //     ref={formRef}
    //   >
    //     {isLoading ? (
    //       <Loader type={"component"} />
    //     ) : (
    //       <>
    //         <p className="font-[600] text-[32px] md:text-[40px] xl:text-[42px] text-[#F37021]">
    //           {title ? title : "Inquiry Form"}
    //         </p>
    //         <Formik
    //           initialValues={{
    //             firstName: "",
    //             lastName: "",
    //             email: "",
    //             phoneNumber: "",
    //             message: "",
    //             programOfIntrest: "",
    //             educationLevel: "",
    //             permission: "",
    //           }}
    //           validationSchema={validationSchema}
    //           onSubmit={handleSubmit}
    //         >
    //           {({ errors, touched }) => (
    //             <Form className="w-full flex flex-col items-center gap-8">
    //               <div className="w-full xl:w-[90%] flex gap-8 md:gap-4 xl:gap-5 flex-col md:flex-row">
    //                 <div className=" w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
    //                     First Name <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="firstName"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.firstName && touched.firstName
    //                         ? "border-red-500"
    //                         : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="firstName"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //                 <div className="w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
    //                     Last Name <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="lastName"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.lastName && touched.lastName
    //                         ? "border-red-500"
    //                         : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="lastName"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //               </div>
    //               <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
    //                 <div className=" w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
    //                     Email Address <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="email"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.email && touched.email ? "border-red-500" : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="email"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //                 <div className="w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
    //                     Phone <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="phoneNumber"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.phoneNumber && touched.phoneNumber
    //                         ? "border-red-500"
    //                         : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="phoneNumber"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //               </div>
    //               <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
    //                 <div className="w-full md:w-[calc(50%_-_10px)] flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
    //                     Education Level{" "}
    //                     <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     name="educationLevel"
    //                     as="select"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.educationLevel && touched.educationLevel
    //                         ? "border-red-500"
    //                         : ""
    //                     }`}
    //                   >
    //                     <option value="" disabled hidden>
    //                       Select Education
    //                     </option>
    //                     {educationLevelData?.data?.map((item) => (
    //                       <option value={item.id}>
    //                         {item.educationName || item.educationname}
    //                       </option>
    //                     ))}
    //                   </Field>
    //                   <ErrorMessage
    //                     name="educationLevel"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //                 <div className="w-full md:w-[calc(50%_-_10px)] flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
    //                     Program Of Interest{" "}
    //                     <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     as="select"
    //                     name="programOfIntrest"
    //                     type="text"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.programOfIntrest && touched.programOfIntrest
    //                         ? "border-red-500"
    //                         : ""
    //                     }`}
    //                   >
    //                     <option value="" disabled hidden>
    //                       Select Program
    //                     </option>
    //                     {getcourses_data?.data?.map((course) => (
    //                       <option value={course.id}>{course.coursename}</option>
    //                     ))}
    //                   </Field>
    //                   <ErrorMessage
    //                     name="programOfIntrest"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //               </div>
    //               {!title && (
    //                 <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
    //                   <div className=" w-full flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
    //                       Message <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <Field
    //                       as="textarea"
    //                       name="message"
    //                       rows="5"
    //                       className={` border border-[#989898] rounded-[5px] p-3 ${
    //                         errors.message && touched.message
    //                           ? "border-red-500"
    //                           : ""
    //                       }`}
    //                     />
    //                     <ErrorMessage
    //                       name="message"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //               )}

    //               <div className="xl:w-[90%]">
    //                 <div className="flex gap-3 w-full">
    //                   <Field
    //                     id="permission"
    //                     name="permission"
    //                     type="checkbox"
    //                     className="h-[21px] w-[21px]"
    //                   />
    //                   <label
    //                     htmlFor="permission"
    //                     className="font-[400] text-[14px] md:text-[16px] text-primary"
    //                   >
    //                     By clicking the submit button, I give PIE Education
    //                     permission to call, text, and/or email me information
    //                     about its educational programs at the phone number and
    //                     email address provided.
    //                   </label>
    //                 </div>

    //                 <ErrorMessage
    //                   name="permission"
    //                   component="div"
    //                   className="text-red-500 mt-2"
    //                 />
    //               </div>
    //               <div className="flex gap-3 w-full xl:w-[90%] justify-center mt-3">
    //                 <button type="submit" className="orangeHoverButton">
    //                   Submit
    //                 </button>
    //                 <button
    //                   type="button"
    //                   className="orangeOutlineButton"
    //                   onClick={() => navigate(-1)}
    //                 >
    //                   Cancel
    //                 </button>
    //               </div>
    //             </Form>
    //           )}
    //         </Formik>
    //       </>
    //     )}
    //   </div>
    // </div>
  );
}

export default RequestInfo;
